import * as React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import { selectStockinById } from 'features/stockins/stockinsSlice';
import { Box, Button } from '@mui/material';
import parseDate from 'utils/parseDate'
import currency from 'utils/currency';
const StockinInvoice = (props) => {
    console.log("render");
    const { id } = useParams();
    const stockin = useSelector((state) => selectStockinById(state, id));

    const styles = {

        largeIcon: {
          width: 60,
          height: 60,
        },
      
      };
    return (
        <Box  sx={{border:"1px solid #ddd",p:3}}>
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Box  sx={{display:"flex"}}>
                            <Button variant="outlined" sx={{backgroundColor:"darkred",borderColor:"darkred",mr:1}}>
                                <Typography variant="h4" sx={{color:"white"}}><strong>LS</strong></Typography>
                            </Button>
                            <Box>
                            <Typography variant="h4" color="darkred">LINCOLNSHIRE STATICS LTD</Typography>
                            <Typography variant="p">The UK's leading static caravan dealer</Typography>
                            </Box>
                        </Box>
                    </TableCell>
                    <TableCell align="right">
                        <Typography variant="p">STOCKIN FORM</Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        <Table sx={{mt:8}}>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography variant="h6" color="darkred">Purchase details</Typography>
                    </TableCell>
                    <TableCell valign="top">
                        <Typography variant="h6" color="darkred">Seller details</Typography>
                        
                    </TableCell>
                </TableRow>
                <TableRow sx={{  }}>
                    <TableCell sx={{ verticalAlign: "top",borderBottom:0}}>
                        <table>
                            <tbody>
                                <tr><td><Typography variant="p">Purchase date:</Typography></td><td>{ parseDate(stockin.created_at)}</td></tr>
                                <tr><td><Typography variant="p">Reference No:</Typography></td><td>{ stockin.reference}</td></tr>
                                <tr><td><Typography variant="p">Contect No:</Typography></td><td>{ stockin.telephone}</td></tr>
                            </tbody>
                        </table>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top",borderBottom:0}}>
                        <table>
                            <tbody>
                                <tr><td><Typography variant="p">{ stockin.seller_name}</Typography></td></tr>
                                <tr><td><Typography variant="p">{ stockin.address}</Typography></td></tr>
                                <tr><td><Typography variant="p">{ stockin.town}</Typography></td></tr>
                                <tr><td><Typography variant="p">{ stockin.postcode}</Typography></td></tr>
                            </tbody>
                        </table>
                    </TableCell>               
                </TableRow>
            </TableBody>
        </Table>

        <Table sx={{mt:5}}>
            <TableBody>
                <TableRow>
                    <TableCell><Typography variant="p">{stockin.make}</Typography></TableCell>
                    <TableCell><Typography variant="p">{stockin.model}</Typography></TableCell>
                    <TableCell><Typography variant="p">{stockin.length}x{stockin.width}</Typography></TableCell>
                    <TableCell><Typography variant="p">{stockin.no_of_beds} beds</Typography></TableCell>
                    <TableCell><Typography variant="p">{stockin.reference}</Typography></TableCell>
                    <TableCell><Typography variant="p">{currency.format(stockin.price)}</Typography></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell colSpan={6} sx={{borderBottom:0}}><Typography variant="p"></Typography></TableCell>
                    
                    
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4} sx={{borderBottom:0}}><Typography variant="p"></Typography></TableCell>
                    <TableCell><Typography variant="p">Unit Cost</Typography></TableCell>
                    <TableCell><Typography variant="p"><strong>{currency.format(stockin.price)}</strong></Typography></TableCell>
                    
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4} sx={{borderBottom:0}}><Typography variant="p"></Typography></TableCell>
                    <TableCell><Typography variant="p">Transport cost</Typography></TableCell>
                    <TableCell><Typography variant="p"><strong>{currency.format(stockin.diesel_total)}</strong></Typography></TableCell>
                    
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4} sx={{borderBottom:0}}><Typography variant="p"></Typography></TableCell>
                    <TableCell><Typography variant="p">Repairs cost</Typography></TableCell>
                    <TableCell><Typography variant="p"><strong>{currency.format(stockin.repairs_total)}</strong></Typography></TableCell>
                    
                </TableRow>
                <TableRow>
                    <TableCell colSpan={4} sx={{borderBottom:0}}><Typography variant="p"></Typography></TableCell>
                    <TableCell><Typography variant="p">TOTAL COST</Typography></TableCell>
                    <TableCell><Typography variant="p"><strong>{currency.format(stockin.price + stockin.diesel_total + stockin.repairs_total)}</strong></Typography></TableCell>
                    
                </TableRow>
            </TableBody>
        </Table>
        <Table sx={{mt:8}}>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Typography variant="h6" color="darkred">Payment Information</Typography>
                    </TableCell>
                    <TableCell valign="top">
                        <Typography variant="h6" color="darkred">Notes</Typography>
                        
                    </TableCell>
                </TableRow>
                <TableRow sx={{  }}>
                    <TableCell sx={{ verticalAlign: "top",borderBottom:0}}>
                        
                        Deposit <strong>{currency.format(stockin.deposit)}</strong> on <strong>{parseDate(stockin.deposit_paid_on)}</strong> by {stockin.deposit_payment_method}<br />
                        Balance <strong>{currency.format(stockin.price - stockin.deposit)}</strong> on <strong>{parseDate(stockin.balance_paid_on)}</strong> by {stockin.balance_payment_method}<br />
                        
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top",borderBottom:0}}>

                    </TableCell>               
                </TableRow>
            </TableBody>
        </Table>
        <Box sx={{display:"flex"}}>
            <Typography  variant="p" sx={{pt:3, m:"auto", fontSize:"12px"}}>Lincolnshire Statics Ltd, Glebe Lodge Farm, Gonerby Ln, Allington, Grantham NG32 2DU, Tel: 01949843221</Typography>
        </Box>
        </Box>
    )
}

export default StockinInvoice;