import * as React from 'react';
import { useParams } from 'react-router';
import { useSelector } from 'react-redux';
import { Table, TableBody, TableHead, TableCell, TableRow, Typography } from '@mui/material';
import { selectSaleById } from 'features/sales/salesSlice';
import { Box, Button } from '@mui/material';
import parseDate from 'utils/parseDate'
import currency from 'utils/currency';
import HolidayVillageOutlinedIcon from '@mui/icons-material/HolidayVillageOutlined';
import { makeImgUrl } from 'features/api_url';

const productPhotoUrl = (product) => {

    return product.images.length > 0 
                ? makeImgUrl(product.id,"320",product.images[0].url) 
                : "/static/images/image_placeholder.jpeg";
}

const SaleInvoice = (props) => {

    console.log("render");
    const { id } = useParams();
    const sale = useSelector((state) => selectSaleById(state, id));

    const leftToPay = sale.total - sale.deposit;
    const styles = {

        largeIcon: {
          width: 60,
          height: 60,
        },
      
      };

    return (
        <Box  sx={{border:"1px solid #aaa",p:1}}>
        <Table>
            <TableBody>
                <TableRow>
                    <TableCell>
                        <Box  sx={{display:"flex"}}>
                            <HolidayVillageOutlinedIcon sx={{ fontSize: 70, color:"darkred" }} />
                            <Box>
                            <Typography variant="h5" color="darkred" sx={{mt:1,ml:1,letterSpacing:-1}}>LINCOLNSHIRE STATICS LTD</Typography>
                            <Typography variant="p" sx={{mt:1,ml:1,fontSize:"12px"}}>The UK's leading static caravan dealership</Typography>
                            </Box>
                        </Box>
                    </TableCell>
                    <TableCell align="right">
                        <Typography variant="h5">INVOICE</Typography>
                    </TableCell>
                </TableRow>
            </TableBody>
        </Table>
        <Table sx={{mt:5}}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography variant="p" color="darkred">Invoice details</Typography>
                    </TableCell>
                    <TableCell valign="top">
                        <Typography variant="p" color="darkred">Billing address</Typography>
                        
                    </TableCell>
                    <TableCell valign="top">
                        <Typography variant="p" color="darkred">Delivery address</Typography>
                        
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow sx={{  }}>
                    <TableCell sx={{ verticalAlign: "top",borderBottom:0, fontSize:"12px"}}>
                        <table>
                            <tbody>
                                <tr><td><Typography variant="p">Purchase date:</Typography></td><td>{ parseDate(sale.created_at)}</td></tr>
                                <tr><td><Typography variant="p">Reference No:</Typography></td><td>INV{ sale.invoice_no}</td></tr>
                                <tr><td><Typography variant="p">Mobile:</Typography></td><td>{ sale.customers[0].mobile}</td></tr>
                                <tr><td><Typography variant="p">Landline:</Typography></td><td>{ sale.customers[0].telephone}</td></tr>
                            </tbody>
                        </table>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top",borderBottom:0, fontSize:"12px"}}>
                        <table>
                            <tbody>
                                <tr><td><Typography variant="p" sx={{fontWeight:600}}>{ sale.customers[0].name}{' '}{ sale.customers[0].surname}</Typography></td></tr>
                                <tr><td><Typography variant="p">{ sale.customers[0].addresses[0].address_line_1}</Typography></td></tr>
                                <tr><td><Typography variant="p">{ sale.customers[0].addresses[0].address_line_2}</Typography></td></tr>
                                <tr><td><Typography variant="p">{ sale.customers[0].addresses[0].town}</Typography></td></tr>
                                <tr><td><Typography variant="p">{ sale.customers[0].addresses[0].postcode}</Typography></td></tr>
                            </tbody>
                        </table>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top",borderBottom:0, fontSize:"12px"}}>
                        <table>
                            <tbody>
                                <tr><td><Typography variant="p">{ sale.deliveries[0].addresses[0].address_line_1}</Typography></td></tr>
                                <tr><td><Typography variant="p">{ sale.deliveries[0].addresses[0].address_line_2}</Typography></td></tr>
                                <tr><td><Typography variant="p">{ sale.deliveries[0].addresses[0].town}</Typography></td></tr>
                                <tr><td><Typography variant="p">{ sale.deliveries[0].addresses[0].postcode}</Typography></td></tr>
                            </tbody>
                        </table>
                    </TableCell>                      
                </TableRow>
            </TableBody>
        </Table>

        <Table sx={{mt:3, border:"1px solid #ddd"}} size="small">
            <TableBody>
                { sale?.products && sale.products.map(product => 
                    <TableRow key={product.id}>
                        <TableCell><img src={productPhotoUrl(product)} alt="Product" width={"100px"} /></TableCell>
                        <TableCell><Typography variant="p">{product.make}</Typography></TableCell>
                        <TableCell><Typography variant="p">{product.model}</Typography></TableCell>
                        <TableCell><Typography variant="p">{product.length}x{product.width}</Typography></TableCell>
                        <TableCell><Typography variant="p">{product.beds} beds</Typography></TableCell>
                        <TableCell><Typography variant="p">{product.reference}</Typography></TableCell>
                        <TableCell><Typography variant="p">{currency.format(product.price)}</Typography></TableCell>
                    </TableRow>
                )}
                { sale?.extras && sale.extras.length > 0 && sale.extras.map(extra =>
                    <TableRow key={extra.id}>
                        <TableCell colSpan={5} sx={{borderBottom:0}}><Typography variant="p"></Typography></TableCell>
                        <TableCell><Typography variant="p">{extra.quantity} x {extra.description}</Typography></TableCell>
                        <TableCell><Typography variant="p">{currency.format(extra.quantity * extra.price)}</Typography></TableCell>
                    </TableRow>
                )}
                <TableRow>
                    <TableCell colSpan={6} sx={{borderBottom:0}}><Typography variant="p"></Typography></TableCell>
                    
                    
                </TableRow>

                <TableRow>

                    <TableCell colSpan={5} sx={{borderBottom:0}}>
                        <b>NOTES:</b> <Typography variant={'caption'}>{sale.extra_info}</Typography>
                    </TableCell>
                    <TableCell><Typography variant="p"><strong>TOTAL</strong></Typography></TableCell>
                    <TableCell><Typography variant="p">{currency.format(sale.total)}</Typography></TableCell>
                </TableRow>

                <TableRow>
                    <TableCell colSpan={5} sx={{borderBottom:0}}><Typography variant="p"></Typography></TableCell>
                    <TableCell><Typography variant="p"><strong>DEPOSIT</strong></Typography></TableCell>
                    <TableCell><Typography variant="p">{currency.format(sale.deposit)}</Typography></TableCell>
                </TableRow>

                <TableRow>
                    <TableCell colSpan={5} sx={{borderBottom:0}}><Typography variant="p"></Typography></TableCell>
                    <TableCell><Typography variant="p"><strong>BALANCE</strong></Typography></TableCell>
                    <TableCell align={'left'}><Typography variant="p">{leftToPay == 0 ? "Paid" : currency.format(leftToPay)}</Typography></TableCell>
                    
                </TableRow>
            </TableBody>
        </Table>
        <Table sx={{mt:3}}>
            <TableHead>
                <TableRow>
                    <TableCell>
                        <Typography variant="p" color="darkred">Terms & Conditions</Typography>
                    </TableCell>
                    <TableCell valign="top">
                        <Typography variant="p" color="darkred">Payment Details</Typography>
                        
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <TableRow sx={{  }}>
                    <TableCell sx={{ verticalAlign: "top",borderBottom:0, width:"60%"}}>
                        <Box sx={{minWidth:"400px",fontSize:"12px"}}>
                            <ol padding={0}>
                                <li>All statics are sold without warranty</li>
                                <li>All statics are sold as seen unless prior agreement</li>
                                <li>Balance needs to be paid within 10 days of deposit</li>
                                <li>Delivery can be booked when full payment is made</li>
                                <li>It is buyer responsibility to re-check all gas/electrical appliances</li>
                                <li>It's buyer responsibility to allow access when delivered</li>
                                <li>Deposit is non-refundable unless prior agreement</li>
                                <li>Siting(final position by land rover on pitch, jack and level) is not included, but siting package can be added at additional cost.</li>
                                <li>Sold for residential use</li>
                                <li>Goods sold on this invoice are subject to VAT Notice 718 (VAT Margin Scheme)</li>
                            </ol>
                        </Box>
                    </TableCell>
                    <TableCell sx={{ verticalAlign: "top",align:"right",borderBottom:0, width:"40%",pr:1,fontSize:"12px"}} align="right">
                    <strong>Please make payment to:</strong>
                    <br />
                    Lincolnshire Statics Ltd<br />
                    Sort code:   30-93-58<br />
                    Account No. : 17937260
                    <br /><br /><br />
                    ...........................................................<br />
                    Customer signature
                    <br /><br />
                    ............................................................<br />
                    Delivered & Approved
                    </TableCell>               
                </TableRow>
            </TableBody>
        </Table>
        <Box sx={{display:"flex"}}>
            <Typography  variant="p" sx={{pt:3, m:"auto", fontSize:"12px"}}>Lincolnshire Statics Ltd, Glebe Lodge Farm, Gonerby Ln, Allington, Grantham NG32 2DU, Tel: 01949 843 221</Typography>
        </Box>
        </Box>
    )
}

export default SaleInvoice;