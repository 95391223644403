import React from 'react';
import { Box,Button, Alert, Typography, Table, TableRow, TableCell, TableBody, LinearProgress, ToggleButtonGroup, ToggleButton } from '@mui/material';
import FBContext from 'components/facebook/FBContext';
import { useDispatch, useSelector } from 'react-redux';
import { selectUser } from 'features/users/usersSlice';
import { useSnackbar } from 'notistack';
import { productUpdated } from 'features/products/productsSlice';
import { set } from 'date-fns';

const emoticons = {
    checkmark: "\u{2705}\u{2705}\u{2705}",
    fork: "\u{d83d}\u{dd31}\u{d83d}\u{dd31}\u{d83d}\u{dd31}",
    flower: "\u{269c}\u{fe0f}\u{269c}\u{fe0f}\u{269c}\u{fe0f}",
    heart: "\u{2764}\u{FE0F}\u{2764}\u{FE0F}\u{2764}\u{FE0F}"
}

const post_footer = `\n\n\n\nAt Lincolnshire Statics Limited, we stock a range of used caravans to suit all budgets and lifestyles so we’re  confident we will have the right caravan for you.
\n\n\n\n
We supply caravans for use as on-site temporary accommodation during self builds, home renovation or for other agricultural use.
\n\n\n\n
FREE UK DELIVERY WITH EVERY PURCHASE.
\n\n\n\n
We offer Buy back option for those looking to use their caravan temporarily whilst either building or renovating the property
\n\n\n\n
Get your static caravan sited in no time by our experienced siting team and rest assured its done the right way
\n\n\n\n
For more information please contact the office on (01949) 843221.
\n\n\n\n
Lincolnshire Statics Ltd\n
Glebe Lodge Farm, \n
Gonerby Lane\n
Alington\n
NG32 2DU \n
\n\n\n\n
Please visit our website lincolnshirestatics.com for more information and more statics caravans`;

const parseEmoticon = (name) => {
    return decodeURI(emoticons[name]);
}

const PostProductOnFacebook = (props) => {

    const { product } = props;
    const [FbUploadPhotos, setFbUploadPhotos] = React.useState([]);
    const [FbUploadInit, setFbUploadInit] = React.useState(false);
    const [FbPostStatus, setFbPostStatus] = React.useState(null);
    const [emoticon, setEmoticon] = React.useState('checkmark');
    const dispatch = useDispatch();
    const {enqueueSnackbar} = useSnackbar();
    const loggedInUser = useSelector(selectUser);

    const FB = React.useContext(FBContext);

    React.useEffect(() => {
        FB.getPosts();
    },[]);

    const createPhotosArray = () => {
        let image_urls = [];
        if(product && product.images && product.images.length > 0){
            product.images.forEach(image => {
                image_urls.push(
                    { url : "https://api.lincolnshirestatics.com/storage/images/uploads/" + product.id + "/1024/" + image.url, 
                        order:image.order, 
                        id: image.id, 
                        upload_id: null,
                        done:false }
                );
            });
            setFbUploadPhotos(image_urls);
        }
    }

    React.useEffect(()=> {
        console.log('Updated fb upload photos');
        createPhotosArray();
    },[product]);
  
    const uploadedPhotosSuccessCount = React.useMemo(
        () => { 
            return FbUploadPhotos 
                ? FbUploadPhotos.reduce((acc,photo) => { return photo.upload_id ? acc+1 : acc },0)
                : null;
        },
        [FbUploadPhotos]
    );
    
    const uploadedPhotosDoneCount = React.useMemo(
        () => { 
            return FbUploadPhotos 
                ? FbUploadPhotos.reduce((acc,photo) => { return photo.done ? acc+1 : acc },0)
                : null;
        },
        [FbUploadPhotos]
    );

    const postHasPhotos = FbUploadPhotos.length > 0;
    const allPhotosUploaded = uploadedPhotosSuccessCount == FbUploadPhotos.length;
    const uploadFailed = (uploadedPhotosDoneCount == FbUploadPhotos.length) && (uploadedPhotosSuccessCount != FbUploadPhotos.length)

    // Curried function callback for FB photo upload
    const photoCallback = (photo) => (response) => {
        console.log("Processing photo " + photo.id);
        setFbUploadPhotos((currentState) => {
            return currentState.map(item => 
                {
                    if (item.id == photo.id){
                        return {
                            ...item, 
                            upload_id: response.status === 'success' ? response.payload.id : null,
                            done:true
                        };
                    }
                    return { ...item };
                });
        });

    }

    const postCallback = (product) => (response) => {
        console.log("POST RESPONSE: ", response);
        setFbPostStatus(response);
        if(response.status == 'success'){

            let postData = {
                user_id : loggedInUser.id,
                product_id: product.id,
                fb_post_id: response.payload.id,
                photos_info: allPhotosUploaded ? JSON.stringify(FbUploadPhotos) : null,
                extra_info: null,

            }
            dispatch(productUpdated({ id: product.id, fb_post: postData}))
            .then(result => {
        
                if(result.type === "products/productUpdated/fulfilled"){         
                    enqueueSnackbar("FB post saved.");
                }
                else enqueueSnackbar("FB POST NOT SAVED TO DB");
            });
    
            setFbUploadInit(false);
            createPhotosArray();
        }

    }

    const retryFailedPhotosUpload = () => {

        let failedUploads = FbUploadPhotos.filter((photo) => photo.upload_id == null);
        console.log("Failed items: ", failedUploads);
        failedUploads.forEach(image => {
            FB.uploadPhotoToPage(image.url, photoCallback(image));
        });
    }
    const postProductToFacebook = () => {

        setFbPostStatus(null);
        const dg = product.dg ? "DG" : "SG";
        const ch = product.ch ? "CH" : "";
        const post_description = `${emoticons[emoticon]} ${product.make} ${product.model} ${product.length}x${product.width} ${dg} ${ch} £${product.price} ${emoticons[emoticon]}\n\n\n\n${product.extra_info}`;
        let postData = { message: post_description + post_footer }

        if(FbUploadPhotos && allPhotosUploaded){

            let photos_array = FbUploadPhotos.map((photo) => {
                return {"media_fbid": photo.upload_id};
            });
            postData.attached_media = photos_array;
        };

        console.log("POST DATA: ", postData);
        FB.postToPage(postData, postCallback(product));

    };
    const uploadPhotosToFacebook = () => {
        setFbUploadInit(true);

        console.log(product);
        if(FbUploadPhotos && FbUploadPhotos.length > 0){
            FbUploadPhotos.forEach(image => {

                FB.uploadPhotoToPage(image.url, photoCallback(image));
            });
        }


        return;

    }

    const normaliseProgress = (value, max) => Math.floor(((value - 0) * 100) / (max - 0));

    return (
        <Box sx={{minWidth:'300px'}}>
            
            <Box sx={{display:'flex', justifyContent: 'space-between'}}>
            {decodeURI(emoticons.heart)}
                <Typography variant="h4" sx={{mb:2}}>Facebook Page</Typography>
                { FB.LoginButton() }
            </Box>
            
            {FB.isUserLoggedIn ? (  
            <>
                

                { postHasPhotos &&
                    <>
                        { allPhotosUploaded ? (
                        <Alert severity={'success'} variant={'outlined'} sx={{marginY:1}}>Photos uploaded successfully</Alert>
                        ) :(
                        <Alert severity={'info'} variant={'outlined'} sx={{marginY:1}}>To include photos with this post, please upload them first</Alert>
                        )
                        }
                        {uploadFailed &&
                        <Alert severity={'error'} variant={'outlined'} sx={{marginY:1}}>Some photos failed to upload. Please retry.</Alert>
                        }
                        <Box sx={{display:'flex', columnGap:1, flexDirection:'row', alignItems:'center',mb:1}}>
                            <Button variant={'outlined'} onClick={uploadPhotosToFacebook} disabled={FbUploadInit}>
                                UPLOAD
                            </Button>
                            
                            <Typography variant={'caption'}>Processesed: {uploadedPhotosDoneCount} of {FbUploadPhotos.length } Uploaded: {uploadedPhotosSuccessCount}</Typography>
                            {uploadFailed &&
                                <Button variant={'outlined'} onClick={retryFailedPhotosUpload}>Retry</Button>
                            }
                        </Box>
                        { FbUploadInit &&
                            <Box sx={{marginY:1}}>
                            <LinearProgress variant="determinate" value={normaliseProgress(uploadedPhotosSuccessCount, FbUploadPhotos.length)} />
                            </Box>
                        }

                    </>
                }
                <Box>
                    <Box
                        sx={{mt:2,mb:1,display:'flex', justifyContent:'flex-start'}}
                    >
                        <ToggleButtonGroup
                            value={emoticon}
                            exclusive
                            onChange={(e,emot) => setEmoticon(emot)}
                            aria-label="emoticons"
                            >
                            <ToggleButton value="checkmark" aria-label="original">
                                {decodeURI(emoticons.checkmark)}
                            </ToggleButton>
                            <ToggleButton value="fork" aria-label="large">
                                {decodeURI(emoticons.fork)}
                            </ToggleButton>
                            <ToggleButton value="flower" aria-label="medium">
                                {decodeURI(emoticons.flower)}
                            </ToggleButton>
                            <ToggleButton value="heart" aria-label="small">
                                {decodeURI(emoticons.heart)}
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Box>
                    <Button 
                        sx={{mb:1}}
                        variant={'outlined'} 
                        onClick={postProductToFacebook}
                        disabled={FB.isPublishing}
                    >
                        POST ON FACEBOOK
                    </Button>
                    {FB.isPublishing && <LinearProgress /> }
                    
                </Box>
                <Box sx={{mt:1}}>
                    { FbPostStatus &&
                    <>
                        { FbPostStatus.status == 'success' ? (
                            <Alert variant={'filled'} severity={'success'}>Post published on facebook</Alert>
                        ) : (
                            <Alert variant={'filled'} severity={'error'}>Post failed to publish. Try again</Alert>
                        )
                        }
                    </>
                    }
                </Box>
            </>
            ) : (
                <Alert severity={'info'} sx={{mt:1}}>You need to login to facebook to post this product</Alert>
            )
            }
        </Box>
    );
}

export default PostProductOnFacebook;